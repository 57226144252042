import React from 'react'

import { styles } from '../styles/styles.jsx'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { name: '', company: '', type: '', date: '', email: '', phone: '', message: '' }
  }

  handleSubmit = e => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        'name-input': 'name',
        'company-input': 'company',
        'event-type': 'event-type',
        'event-date': 'event-date',
        'phone-input': 'phone',
        'email-input': 'email',
        'message-input': 'message',
        ...this.state,
      }),
    })
      .then(() =>
        alert(
          'Thanks for contacting Monx Design House, we will get back to you shortly.'
        )
      )
      .catch(error => alert(error))

    this.setState({ name: '', company: '', type: '', date: '', email: '', phone: '', message: '' })
    e.preventDefault()
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  render() {
    // const { name, company, email, phone, message } = this.state
    return (
      <form
        action="/success"
        method="post"
        name="contact"
        data-netlify
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
        className={styles.contactForm}
      >
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" />
          </label>
        </p>
        <div className={styles.contactFormContainer}>
          <div className={styles.contactFormInput}>
            <div className={styles.contactFormInputPadding}>
              <input
                type="text"
                placeholder="Name"
                required
                name="name"
                onChange={this.handleChange}
                value={this.state.name}
              />
            </div>
          </div>
          <div className={styles.contactFormInput}>
            <div className={styles.contactFormInputPadding}>
              <input
                type="text"
                placeholder="Company"
                className=""
                required
                name="company"
                onChange={this.handleChange}
                value={this.state.company}
              />
            </div>
          </div>
          <div className={styles.contactFormInput}>
            <div className={styles.contactFormInputPadding}>
              <input
                type="text"
                placeholder="Event Type"
                className=""
                required
                name="type"
                onChange={this.handleChange}
                value={this.state.type}
              />
            </div>
          </div>
          <div className={styles.contactFormInput}>
            <div className={styles.contactFormInputPadding}>
              <input
                type="text"
                placeholder="Event Date"
                className=""
                required
                name="date"
                onChange={this.handleChange}
                value={this.state.date}
              />
            </div>
          </div>
          <div className={styles.contactFormInput}>
            <div className={styles.contactFormInputPadding}>
              <input
                type="email"
                placeholder="Email"
                className=""
                required
                name="email"
                onChange={this.handleChange}
                value={this.state.email}
              />
            </div>
          </div>
          <div className={styles.contactFormInput}>
            <div className={styles.contactFormInputPadding}>
              <input
                type="tel"
                placeholder="Phone"
                className=""
                required
                name="phone"
                onChange={this.handleChange}
                value={this.state.phone}
              />
            </div>
          </div>
          <div className={styles.contactFormMessage}>
            <div className={styles.contactFormInputPadding}>
              <textarea
                type=""
                placeholder="Message"
                className=""
                required
                rows="5"
                name="message"
                onChange={this.handleChange}
                value={this.state.message}
              />
            </div>
          </div>
          <div className={styles.contactFormButtonContainer}>
            <div data-netlify-recaptcha />

            <button className={styles.contactFormButton} value="Send">
              Get In Touch
            </button>
          </div>
        </div>
      </form>
    )
  }
}
