import React from 'react'
import { graphql } from 'gatsby'
// import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Layout from '../components/layout'
import ContactForm from '../components/contactForm'

import { styles } from '../styles/styles.jsx'

const Contact = ({ data }) => (
  <Layout>
    <BackgroundImage
      className={styles.contactPageBackgroundImage}
      fluid={data.datoCmsContact.contactBackgroundImage.fluid}
    >
      <div className={styles.container}>
        <div className={styles.contactContainer}>
          <h1 className={styles.contactTitle}>
            {data.datoCmsContact.contactTitle}
          </h1>
          <p className={styles.contactText}>
            {data.datoCmsContact.contactText}
          </p>
          <div>
            <ContactForm />
            {/* <form
              action="/success"
              method="POST"
              data-netlify="true"
              name="contact us"
              data-netlify-honeypot="bot-field"
              data-netlify-recaptcha="true"
              className={styles.contactForm}
            >
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" />
                </label>
              </p>
              <div className={styles.contactFormContainer}>
                <div className={styles.contactFormInput}>
                  <div className={styles.contactFormInputPadding}>
                    <input
                      type="text"
                      placeholder="Name"
                      required
                      name="name"
                    />
                  </div>
                </div>
                <div className={styles.contactFormInput}>
                  <div className={styles.contactFormInputPadding}>
                    <input
                      type="text"
                      placeholder="Company"
                      className=""
                      required
                      name="company"
                    />
                  </div>
                </div>
                <div className={styles.contactFormInput}>
                  <div className={styles.contactFormInputPadding}>
                    <input
                      type="text"
                      placeholder="Event Type"
                      className=""
                      required
                      name="type"
                    />
                  </div>
                </div>
                <div className={styles.contactFormInput}>
                  <div className={styles.contactFormInputPadding}>
                    <input
                      type="text"
                      placeholder="Event Date"
                      className=""
                      required
                      name="date"
                    />
                  </div>
                </div>
                <div className={styles.contactFormInput}>
                  <div className={styles.contactFormInputPadding}>
                    <input
                      type="email"
                      placeholder="Email"
                      className=""
                      required
                      name="email"
                    />
                  </div>
                </div>
                <div className={styles.contactFormInput}>
                  <div className={styles.contactFormInputPadding}>
                    <input
                      type="tel"
                      placeholder="Phone"
                      className=""
                      required
                      name="phone"
                    />
                  </div>
                </div>
                <div className={styles.contactFormMessage}>
                  <div className={styles.contactFormInputPadding}>
                    <textarea
                      type=""
                      placeholder="Message"
                      className=""
                      required
                      rows="5"
                      name="message"
                    />
                  </div>
                </div>
                <div className={styles.contactFormButtonContainer}>
                  <div data-netlify-recaptcha="true"></div>

                  <button className={styles.contactFormButton} value="Send">
                    Get In Touch
                  </button>
                </div>
              </div>
            </form> */}
          </div>
        </div>
      </div>
    </BackgroundImage>
  </Layout>
)

export default Contact

export const ContactQuery = graphql`
  query contactPageQuery {
    datoCmsContact {
      contactBackgroundImage {
        fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      contactTitle
      contactText
    }
  }
`
